import { Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './components/home/home';
import Login from './components/login/login';
import SignUp from './components/signup/signup';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path='/login'>
          <Login></Login>
        </Route>
        <Route path='/' exact>
          <Home />
        </Route>
        <Route path='/signup'>
          <SignUp></SignUp>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
