import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import './signup.css';


const SignUp = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    let history = useHistory();

    const emailHandler = (event) => {
        setEmail(event.target.value);
    }

    const passwordHandler = (event) => {
        setPassword(event.target.value);
    }

    const nameHandler = (event) => {
        setName(event.target.value);
    }

    const confirmPasswordHandler = (event) => {
        setConfirmPassword(event.target.value);
    }

    const clearForm = () => {
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setName('');
    }

    const submitSignupForm = (event) => {
        event.preventDefault();
        if (password == confirmPassword) {
            const user = {
                email: email,
                password: password,
                name: name
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(user)
            };
            fetch(
                'https://api.sumantgupta.com.np/auth/signup', 
                // 'http://localhost:3000/auth/signup',
                requestOptions)
                .then(response => response.json())
                .then(data => {
                    history.push('/login')
                }
                );

            clearForm();
        } else {
            alert('passwords dont match!!!');
        }

    }




    return <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="card mt-4 mx-auto signup-card" >
                    <div className="text-center mb-4">
                        <img src="https://angular.sumantgupta.com.np/assets/images/logo.png" alt="logo" height="80px" />
                        <h4>Welcome</h4>
                        <p>Sign up by entering your informations below.</p>
                    </div>
                    <div className="card-body">
                        <form onSubmit={submitSignupForm}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input type="text" className="form-control" value={name} onChange={nameHandler} placeholder="john banner" />
                                {/* <small>Please enter a valid name.</small> */}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Email</label>
                                <input type="email" className="form-control" value={email} onChange={emailHandler} placeholder="name@example.com" />
                                {/* <small>Please enter a valid email ID.</small> */}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password (min length 4)</label>
                                <input type="password" className="form-control" value={password} onChange={passwordHandler} placeholder="**********" />
                                {/* <small>Please enter a valid Password.</small> */}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                <input type="password" className="form-control" value={confirmPassword} onChange={confirmPasswordHandler} placeholder="**********" />
                                {/* <small>Passwords dont match.</small> */}
                            </div>
                            <div className="mb-3">
                                <button type="button" className="btn btn-outline-dark mx-2" onClick={clearForm}>Cancel</button>
                                <button type="submit" className="btn btn-secondary mx-2" >Sign Up</button>
                            </div>
                        </form>
                        <div className="d-flex flex-row-reverse">
                            <div>
                                <Link to='/login'>Back to Login?</Link>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

}


export default SignUp;