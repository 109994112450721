import React from 'react';
import { useHistory } from 'react-router-dom';
import './header.css';

const Header = () => {
    
    let history = useHistory(); 
    const logoutHandle=()=>{
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        
        history.push("/login");

    }
    return <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container ">
            <a className="navbar-brand" href="#">Leapfrog</a>
            <button className="btn btn-secondary" onClick={logoutHandle}>Logout </button>

        </div>
    </nav>

}

export default Header;