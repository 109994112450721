import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import './login.css';

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const emailHandler = (event) => {
        setEmail(event.target.value);
    }

    const passwordHandler = (event) => {
        setPassword(event.target.value);
    }

    const clearForm = () => {
        setEmail('');
        setPassword('');
    }
    let history = useHistory();
    const submitLoginForm = (event) => {
        event.preventDefault();
        const user = {
            userName: email,
            password: password
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        };
        fetch(
            'https://api.sumantgupta.com.np/auth/login', 
            // 'http://localhost:3000/auth/login',
            requestOptions)
        .then(response => response.json())
        .then(data => {
            localStorage.setItem('token', data.token);
            localStorage.setItem('userId', data.loadedUserId);
            
            history.push("/");
        }
            );

        clearForm();
    }




    return <div className="container mt-5">
        <div className="row">
            <div className="col-12 mt-5">
                <div className="card mx-auto login-card" >
                    <div className="card-body">
                        <div className="text-center mb-4">
                            <img src="https://angular.sumantgupta.com.np/assets/images/logo.png" alt="logo" height="80px" />
                            <h4>Welcome</h4>
                            <small>Sign in by entering your informations below.</small>
                        </div>


                        <form onSubmit={submitLoginForm}>
                            <div className="mb-3">
                                <input type="email" className="form-control" value={email} onChange={emailHandler} placeholder="name@example.com" />
                                {/* <small className="help-block">Please enter a valid username.</small> */}
                            </div>
                            <div className="mb-3">
                                <input type="password" className="form-control" value={password} onChange={passwordHandler} placeholder="**********" minLength="4" />
                            </div>
                            <div className="mb-3">
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-success mx-2" >Login</button>
                                    <button type="button" className="btn btn-outline-secondary mx-2" onClick={clearForm}>Cancel</button>
                                </div>

                            </div>
                            <small><Link to='/signup'>Don't have account? Sign up here.</Link></small>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
}


export default Login;